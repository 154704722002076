<template>
  <div class="content" style="margin-bottom: 50px;">
    <loader v-if="!pageLoaded"/>
    <div v-else>
      <div v-if="hasOrders">
        <div class="content-header">
          <div style="display: flex;flex-direction: column">
            <p class="content-header-text">Tüm <span style="font-weight: 600;">Satışlarım</span></p>
            <p style="margin-top: 10px; color: #6a7685; font-size: 18px; line-height: 1.56;">
              Bionluk üzerinden sattığın tüm hizmetleri buradan yönetebilirsin.
            </p>
          </div>
        </div>
        <div class="top-menu">
          <div v-if="!isSearchActive" class="menu-option-wrapper">
            <div class="menu-option-item" :class="activeState === null ? 'active' : null" @click="activeState = null" style="margin-left: 15px" :style="activeState === 'open' ? 'border: none; width: 140px' : null">
              Tüm Satışlarım
            </div>
            <div class="menu-option-item" :class="activeState === 'open' ? 'active' : null" @click="activeState = 'open'" :style="activeState === 4 ? 'border: none; width: 140px' : null">
              <span>Devam Edenler</span>
            </div>
            <div class="menu-option-item" :class="activeState === 4 ? 'active' : null" @click="activeState = 4" :style="activeState === -1 ? 'border: none; width: 140px' : null">
              Tamamlananlar
            </div>
            <div class="menu-option-item" :class="activeState === -1 ? 'active' : null" @click="activeState = -1" >
              İptal Olanlar
            </div>
          </div>
          <div v-show="!isSearchActive" class="menu-search-wrapper" @click="activateSearch">
            <div style="height: 100%; display: flex; align-items: center;  justify-content: space-around;">
              <div style="font-size: 14px; font-weight: 500; color: #8b95a1;">
                Kullanıcı ara
              </div>
              <div>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-search.svg" onload="SVGInject(this)" class="search-icon"/>
              </div>
            </div>
          </div>
          <div v-show="isSearchActive" class="menu-search-active-wrapper">
            <input id="menu-search-input" v-model="searchTerm" type="text" @input="onSearchTermChange" @keyup.enter="inputEnter" style="margin-left: 10px">
            <div @click="removeUsernameFilter" style="cursor: pointer; margin-right: 20px;">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon"/>
            </div>
          </div>
        </div>
        <div>
          <div v-if="bodyLoaded" style="margin-top: 10px; margin-bottom: 50px">
            <div v-if="username" style=" font-size: 13px; margin-bottom: 20px;">
              Sadece <span style="font-weight: 500; color: #2d3640;">{{username}}</span> ile aranızdaki siparişler getiriliyor. <span style="color: rgb(230, 50, 98); cursor: pointer;" @click="removeUsernameFilter()">Temizle</span>
            </div>
            <div v-if="bodyLoaded && orders.length === 0">
              <p style="margin-top: 20px;color: #8B95A1;">Bu bölümde bir sipariş bulunamadı.</p>
            </div>
            <router-link style="text-decoration: none!important;" :to="`/orders/${order.order_id}`" v-for="(order, index) in orders" :key="index" class="row-wrapper" :style="`border: solid 1px ${order.borderColor}`" >
              <div class="row-body">
                <div class="row-item-image" style="position: relative">
                  <div class="info-icon-wrapper" :data-balloon="`Ben, ${order.title}`" data-balloon-pos="up" style="position: absolute; top: 5px; left: 5px; display: flex; align-items: center;">
                    <div style="width: 24px; text-align: center; pointer-events: none">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                    </div>
                  </div>
                  <img :src="order.image_url" style="width: 151px; height: 84px;border: solid 1px #eff3f8;border-radius: 5px;"/>
                </div>
                <div class="row-item-dashed">
                  <div style="margin: 15px; position:relative;">
                    <div style="font-size: 11px; color: #8b95a1;">ALICI</div>
                    <div @click.stop.prevent="preventClick" @click="sendMessage(order)" id="send-message-box" class="info-icon-wrapper-send-message" data-balloon="Alıcıya Mesaj Gönder" data-balloon-pos="up" style="position: absolute; right: -10px; top:-10px; display: flex; align-items: center;">
                      <div style="width: 24px; text-align: center" id="send-message-box-2">
                        <img id="send-message-box-3" src="https://gcdn.bionluk.com/site/icon/aws/ic_commentt.svg" onload="SVGInject(this)" class="info-icon-message"/>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 6px">
                      <div style="width: 34px; height: 34px;">
                        <img :src="order.kim.avatar_url" style="width: 34px; height: 34px; border-radius: 50%;"/>
                      </div>
                      <div style="margin-left: 11px; font-size: 12px;">
                        <p style="color: #2d3640;margin-top:2px;">{{ order.kim.username }}</p>
                        <p style="color: #5e6b79; margin-top: 4px; text-overflow: ellipsis; line-height: 1.3; width: 130px; white-space: pre; overflow-x: hidden">{{ order.kim.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row-item-dashed">
                  <div style="margin: 15px">
                    <div style="font-size: 11px; color: #8b95a1;">SİPARİŞ ve TESLİM TARİHİ</div>
                    <div style="display: flex; margin-top: 6px">
                      <div class="basket-wrapper">
                        <div style="margin: auto">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-basket.svg" onload="SVGInject(this)" class="basket-icon"/>
                        </div>
                        <div v-if="order.due_on" class="inner-icon-wrapper">
                          <div style="margin: auto">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-package.svg" onload="SVGInject(this)" class="inner-icon"/>
                          </div>
                        </div>
                      </div>
                      <div style="margin-left: 12px" :style="order.due_on ? null : 'display: flex; flex-direction: column; justify-content: center'">
                        <div class="order-date">
                          {{ Number(order.payed_at) | customDateFormat('dd MMM yyyy') }}
                        </div>
                        <div v-if="order.due_on" class="order-date" style="margin-top: 7px">
                          {{ Number(order.due_on) | customDateFormat('dd MMM yyyy') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row-item-info">
                  <div style="text-align: right">
                    <span class="order-price" :class="order.status !== '-1' ? 'active' : null">{{order.total}}</span>
                    <span class="order-currency" :class="order.status !== '-1' ? 'active' : null">TL</span>
                  </div>
                  <div class="status-text" style="margin-top:10px" :style="`color: ${order.statusTextColor}; border: solid 1px ${order.statusTextColor}`">
                    {{order.statusText}}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <loader v-else/>

          <div v-if="!seemoreLoader && seemorediv && bodyLoaded" @click="retrieveOrders(true)"
               style="cursor: pointer; margin-top:20px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
            Daha fazla gör +
          </div>
          <loader v-else-if="seemoreLoader"/>
        </div>
      </div>
      <div v-else-if="user.seller.gigCount" style="width: 543px; margin-top: 50px; margin-left: 182px; text-align: center">
        <img src="https://gcdn.bionluk.com/site/general/ilNoSales.png" style="width: 244px; height: 210px;">
        <div style="margin-top: 40px; color: #2d3640; font-size: 34px;">
          Henüz hiç satışın yok
        </div>
        <div style="font-size: 18px; color: #5e6b79; line-height: 1.44; margin: 14px auto 0;">
          Sevgili <span style="font-weight: bold;color: #2d3640;">{{ user.firstname || user.username}}</span>, satış yapabilmek için iyi bir profile ve portfolyoya sahip olman çok önemli. Bunun dışında ise hediye bicoin'lerinle ilanını öne çıkarabilir veya sana uygun alıcı isteklerine bakabilirsin.
        </div>
      </div>
      <div v-else style="width: 543px; margin-top: 50px; margin-left: 182px; text-align: center">
        <img src="https://gcdn.bionluk.com/site/cicons/il-first-gig.png" style="width: 335px; height: 183px;">
        <div style="margin-top: 60px; color: #2d3640; font-weight: bold; font-size: 34px;">
          <span style="font-weight: normal;">İlk</span> İlanını Oluştur
        </div>
        <div style="font-size: 18px; color: #5e6b79; line-height: 1.44; margin: 16px auto 0;">
          Sevgili <span style="font-weight: bold;color: #2d3640;">{{ user.firstname || user.username}}</span>, yeteneklerini sergilemek ve satış yapmaya başlamak için etkileyici bir iş ilanı oluşturmalısın.
        </div>
        <div @click="$router.push('/panel/ilanlar/yeni')" class="create-gig-button" style="margin: 40px auto">
          İş İlanı Oluştur
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "src-pages-body-workstation-sales-v3",
    data() {
      return {
        isSearchActive: false,
        hasOrders: false,
        activeState: null,
        pageLoaded: false,
        bodyLoaded: true,
        offset: 0,
        totalCount: null,
        seemorediv: false,
        seemoreLoader: false,
        orders: [],
        limit: 10,
        orderMenu: [],
        searchTerm: "",
        username: null,
        activeTabTimeStamp: null,
        type: "selling",
        interval: null
      }
    },

    methods: {
      preventClick(){
        return false
      },
      routeToOrder(e, order){
        if(e && e.target && e.target.id && (e.target.id === 'send-message-box' || e.target.id === 'send-message-box-2' || e.target.id === 'send-message-box-3') || e.target.id === 'Combined-Shape'){
          return false;
        } else {
          this.$router.push(`/orders/${order.order_id}`)
        }

      },
      sendMessage(order) {


        this.$store.state.clickedFrom = 'Order List';
        let whereFrom = this.$store.state.componentMap.page;


        if(this.buttonLoading){
          return false;
        }
        this.buttonLoading = true;

        this.api.workstation.chatOpen(order.kim.username, 0,1)
          .then(({data}) => {

            let result = data;
            if (result.success) {
              let conversationSendData = {
                r_username: order.kim.username,
                r_avatar_url: order.kim.avatar_url,
                r_id: parseInt(result.data.receiver.id),
                r_uuid: result.data.receiver.uuid,
                fromWhere: whereFrom,
                c_uuid: result.data.c_uuid,
                whereFrom: whereFrom
              };
              this.EventBus.$emit('externalOpenChat', conversationSendData);
              this.buttonLoading = false;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          });


      },
      onSearchTermChange() {
        clearTimeout(this.interval)
        this.interval = setTimeout(this.inputEnter, 1000)
      },
      activateSearch() {
        this.isSearchActive = true
        this.$nextTick(() => {
          let ele = document.getElementById('menu-search-input');
          if(ele){
            ele.focus()
          }
        })
      },
      closeSearch() {
        this.isSearchActive = false
        this.searchTerm = ""
      },
      retrieveOrders(isFromSeeMore) {
        if (!isFromSeeMore) {
          this.offset = 0;
          this.bodyLoaded = false;
        } else {
          this.seemoreLoader = true
        }
        if(this.username){
          this.activeState = null;
        }
        this.activeTabTimeStamp = + new Date();
        let tempTimeStamp = this.activeTabTimeStamp;
        return this.api.orders.retrieveOrders(this.type, this.activeState, this.limit, this.offset, this.username, this.$Progress)
          .then(({data: result}) => {
            if (result.success && this.activeTabTimeStamp === tempTimeStamp) {
              this.offset += result.data.orders.length;
              if (isFromSeeMore) {
                this.orders = this.orders.concat(result.data.orders);
              } else {
                this.orders = result.data.orders
              }

              this.totalCount = result.data.orderCount;
              this.seemorediv = result.data.seemorediv;
              this.orderMenu = result.data.menuItems;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.bodyLoaded = true;
            return result
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.bodyLoaded = true;
          })
        .finally(() => {
          this.bodyLoaded = true
          this.seemoreLoader = false
        })
      },
      inputEnter() {
        clearTimeout(this.interval)
        if (this.searchTerm.length > 1) {
          this.status = null

          this.$router.push(`/panel/satislar?username=${this.searchTerm}`)
        }
        if (this.searchTerm.length === 0) {
          this.status = null;

          this.$router.push(`/panel/satislar`)
        }
      },

      removeUsernameFilter() {
        this.activeState = null;
        this.username = '';
        this.searchTerm = '';
        this.isSearchActive = false;
        this.$router.push(`/panel/satislar`)
      }
    },

    watch: {
      activeState() {
        this.offset = 0
        this.retrieveOrders();
      },
      'route.query.username': function () {
        this.offset = 0
        this.username = (this.route.query.username && this.route.query.username !== 'undefined' && this.route.query.username !== "null") ? this.route.query.username : null;
        this.retrieveOrders();
      },
      username(newVal) {
        if(newVal){
          this.searchTerm = this.username;
          this.activateSearch();
        }
      }
    },

    created() {
      this.username = (this.route.query.username && this.route.query.username !== 'undefined' && this.route.query.username !== "null") ? this.route.query.username : null;

      return Promise.all([
        this.api.orders.retrieveOrders(this.type, null, 2, 0, null, this.$Progress),
        this.retrieveOrders()
      ])
      .then(results => {
        if (results[0]?.data?.data?.orders?.length) {
          this.hasOrders = !!results[0].data.data.orders.length
        }
      })
      .finally(() => {
        this.pageLoaded = true
      })
    }
  }
</script>

<style scoped lang="scss">

  .content {
    margin-left: 56px;
    .content-header{
      margin-top: 30px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-header-text {
        font-size: 34px;
        font-weight: 500;
        color: #2d3640;
      }
    }
    .top-menu{
      width: 843px;
      height: 100px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      .menu-option-wrapper{
        width: 591px;
        height: 60px;
        border-radius: 5px;
        border: solid 1px #eaedf2;
        background-color: rgba(244, 245, 247, 0.2);
        margin: 20px 0 20px 20px;
        display: flex;
        align-items: center;
        .menu-option-item{
          width: 139px;
          height: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          font-size: 14px;
          color: #5e6b79;
          line-height: 17px;
          cursor: pointer;
          border-radius: 0.5px;
          border-right: solid 1px rgba(142, 142, 147, 0.3);
          &:nth-child(4) {
            border: none;
            width: 140px;
          }
          &.active{
            width: 140px;
            height: 38px;
            line-height: 37px;
            border-radius: 2px;
            background-color: #fd4056;
            border: none;
            color: #ffffff;
          }
        }
      }
      .menu-search-wrapper{
        height: 60px;
        width: 183px;
        border-radius: 5px;
        border: solid 1px #eaedf2;
        background-color: rgba(244, 245, 247, 0.2);
        margin: auto 20px auto 20px;
        cursor: pointer;
        .search-icon /deep/ {
          width: 22px;
          height: 22px;
          path{
            fill: #8b95a1;
          }
        }
      }

      .menu-search-active-wrapper{
        height: 60px;
        width: 100%;
        border-radius: 5px;
        border: solid 1px #eaedf2;
        background-color: rgba(244, 245, 247, 0.2);
        margin: auto 20px;
        display: flex;
        align-items: center;
        .delete-icon /deep/ {
          width: 22px;
          height: 22px;
          path{
            fill: #2d3640;
          }
        }
        input{
          float: right;
          margin-right: 10px;
          width: calc(100% - 24px);
          background: rgba(244, 245, 247, 0);
          font-size: 16px;
          font-weight: 600;
          color: #2d3640;
          text-align: left;
          border: none;
          box-sizing: border-box;
        }
      }
    }

    .row-wrapper{
      width: 843px;
      height: 124px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      margin-top: 30px;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
      .row-body{
        height: 84px;
        margin: 20px;
        display: flex;
        .row-item-image{
          width: 151px;
          height: 84px;
          border-radius: 5px;
          border: solid 1px #eff3f8;
          background-color: #f9f9f9;
          .info-icon-wrapper{
            width: 24px;
            height: 24px;
            opacity: 0.5;
            border-radius: 5px;
            background-color: #2d3640;
            cursor: pointer;
            &:hover{
              opacity: 1;
            }
            .info-icon /deep/{
              width: 12px;
              height: 12px;
              path{
                fill: #ffffff;
              }
            }
          }
        }
        .row-item-dashed{
          width: 200px;
          height: 84px;
          border-radius: 5px;
          border: dashed 1px #bfc8d2;
          background-color: rgba(244, 245, 247, 0.2);
          margin-left: 20px;

          .info-icon-wrapper-send-message{
            width: 24px;
            height: 24px;
            opacity: 0.5;
            border-radius: 5px;
            background-color: #2d3640;
            cursor: pointer;
            &:hover{
              opacity: 1;
            }
            .info-icon-message /deep/{
              width: 12px;
              height: 12px;
              path{
                fill: #ffffff;
              }
            }
          }

          .basket-wrapper{
            border-radius: 50%;
            background-color: #ffbf00;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            position: relative;
            .basket-icon /deep/{
              width: 16px;
              height: 14px;
              path{
                fill: #ffffff;
              }
            }
            .inner-icon-wrapper{
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: solid 1px #ffffff;
              background-color: #26ca9a;
              position: absolute;
              display: flex;
              align-items: center;
              bottom: -4px;
              right: -4px;
              .inner-icon /deep/ {
                width: 10px;
                height: 10px;
                path{
                  fill: #ffffff;
                }
              }
            }
          }
          .order-date{
            margin-top: 2px;
            font-size: 12px;
            font-weight: normal;
            color: #5e6b79;
          }
        }
        .row-item-info{
          width: 178px;
          height: 84px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 24px;
          .order-price{
            width: 62px;
            height: 34px;
            font-size: 28px;
            font-weight: bold;
            color: #8b95a1;
            &.active{
              color: #39d1a2;
            }
          }
          .order-currency{
            font-size: 22px;
            font-weight: 500;
            color: #8b95a1;
            &.active{
              color: #39d1a2;
            }
          }
          .status-text{
            padding: 7px 15px;
            border-radius: 4px;
            font-size: 13px;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
  }

  .header {
    display: none;
    background-color: #f2f2f2;
    color: #8b95a1;
    width: 849px;
  }

  .create-gig-button{
    width: 152px;
    height: 46px;
    line-height: 45px;
    border-radius: 2px;
    background-color: #fd4056;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:hover{
      opacity: 0.9;
    }
  }
</style>
